body,html {
    margin: 0;
    padding: 0;
}

body {
    background-color: #333;
}

#app {
    padding: 20px;
    text-align: center;
}

.separator {
    height: 2px;
    width: 100%;
    color: #fff;
    margin: 20px 0;
}

.tab-content {
    padding: 20px;
}
