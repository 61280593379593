body, html {
  margin: 0;
  padding: 0;
}

body {
  background-color: #333;
}

#app {
  text-align: center;
  padding: 20px;
}

.separator {
  height: 2px;
  width: 100%;
  color: #fff;
  margin: 20px 0;
}

.tab-content {
  padding: 20px;
}

/*# sourceMappingURL=index.80e8b97b.css.map */
